import Img from 'gatsby-image'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'

import { H1 } from '../../styles/base'
import { ContentfulArticle } from '../../types/graphql-types'
import Button from '../atoms/button'
import { Category } from '../atoms/category'
import Link from '../atoms/link'

const Tag = styled(Link)`
  ${({ theme }): CSSProp => css`
    font-size: ${theme.font.fontSizeSmall};
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  `}
`

const Tags = styled.div``

const Body = styled.div`
  ${({ theme }): CSSProp => css`
    font-size: ${theme.font.fontSizeBodyDefault};
  `}
`

const FeaturedArticleWrapper = styled.div`
  ${({ theme }): CSSProp => css`
    align-items: center;
    margin-bottom: ${theme.padding.largeScreenDefault};

    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      display: flex;
    }

    .title-link {
      text-decoration: none;
    }

    ${H1} {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    ${Tags} {
      margin-bottom: 3rem;
    }

    ${Body} {
      margin-bottom: 3rem;
    }

    .image {
      flex-basis: 55%;
      flex-shrink: 0;

      @media only screen and ${theme.breakpoints.toNormalScreen} {
        margin: -3.5rem -2rem 3rem;
      }

      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        margin-right: 5.5rem;
      }

      @media only screen and ${theme.breakpoints.fromLargeScreen} {
        margin-right: calc(
          ${theme.layout.columnWidth} / 2 + ${theme.layout.gutterWidth}
        );
      }
    }
  `}
`

const BodyWrapper = styled.div``

const FeaturedArticle = ({
  article,
}: {
  article: ContentfulArticle
}): JSX.Element => {
  return (
    <FeaturedArticleWrapper>
      <div className="image">
        <Link to={`/community/${article.slug}`}>
          <Img
            alt={article.heroImage.title}
            fluid={{ ...article.heroImage.fluid, aspectRatio: 1.5 }}
          />
        </Link>
      </div>
      <BodyWrapper>
        <Category to={`/community/category/${article.category.slug}`}>
          {article.category.title}
        </Category>
        <Link className="title-link" to={`/community/${article.slug}`}>
          <H1>{article.title}</H1>
        </Link>
        <Tags>
          {article.tags.map(tag => {
            return (
              <Tag key={tag.id} to={`/community/tag/${tag.slug}`}>
                {tag.title}
              </Tag>
            )
          })}
        </Tags>
        <Body>{article.metaDescription.metaDescription}</Body>
        <Button to={`/community/${article.slug}`} key={article.id}>
          Read more
        </Button>
      </BodyWrapper>
    </FeaturedArticleWrapper>
  )
}

export default FeaturedArticle
