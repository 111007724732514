import Container from 'components/container'
import Layout from 'components/layout'
import Seo from 'components/molecules/seo'
import ArticlesList from 'components/organisms/articlesList'
import CommunityNav from 'components/organisms/communityNav'
import CommunitySearchBar from 'components/organisms/communitySearchBar'
import CTABlock from 'components/organisms/ctaBlock'
import FeaturedArticles from 'components/organisms/featuredArticles'
import { graphql, PageProps } from 'gatsby'
import { getLocalNodes } from 'helpers/locale'
import React, { useState } from 'react'
import {
  ContentfulArticleConnection,
  ContentfulCtaBlockConnection,
  SitePageContext,
} from 'types/graphql-types'

type CommunityTypes = PageProps & {
  data: {
    ctaBlock: ContentfulCtaBlockConnection
    footerCtaBlock: ContentfulCtaBlockConnection
    articles: ContentfulArticleConnection
  }
  pageContext: SitePageContext
}

const Community = (props: CommunityTypes): JSX.Element => {
  const { featuredArticles, featuredArticleSlugsArray } = props.pageContext
  const { data } = props
  const ctaBlock = getLocalNodes(data.ctaBlock.nodes)
  const footerCtaBlock = getLocalNodes(data.footerCtaBlock.nodes)
  const articles = data.articles.nodes
  const [searchBarShowing, setSearchBarShowing] = useState(false)

  const {
    body: { body: ctaBody },
    cta,
  } = ctaBlock[0]

  const {
    body: { body: footerCtaBody },
    cta: footerCta,
  } = footerCtaBlock[0]

  return (
    <Layout>
      <Seo title="Community" url={props.location.href} />
      {searchBarShowing ? (
        <CommunitySearchBar close={(): void => setSearchBarShowing(false)} />
      ) : (
        <CommunityNav toggleSearch={(): void => setSearchBarShowing(true)} />
      )}

      <FeaturedArticles articles={featuredArticles} />
      <CTABlock body={ctaBody} cta={cta} />
      <Container>
        <ArticlesList
          articles={articles}
          more
          exclude={featuredArticleSlugsArray}
        />
      </Container>
      <CTABlock
        body={footerCtaBody}
        cta={footerCta}
        alternate
        noMarginPadding
      />
    </Layout>
  )
}

export default Community

export const pageQuery = graphql`
  query CommunityQuery($locale: String!, $featuredArticleSlugsArray: [String]) {
    site {
      siteMetadata {
        title
      }
    }
    ctaBlock: allContentfulCtaBlock(filter: { key: { eq: "community.cta" } }) {
      nodes {
        id
        cta {
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulRegionalLink {
            ...RegionalLink
          }
        }
        body {
          body
        }
        node_locale
      }
    }
    articles: allContentfulArticle(
      limit: 7
      filter: {
        node_locale: { eq: $locale }
        slug: { nin: $featuredArticleSlugsArray }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        id
        title
        node_locale
        slug
        category {
          id
          title
          slug
        }
        tags {
          id
          title
          slug
        }
        body {
          raw
        }
        metaDescription {
          metaDescription
        }
        heroImage {
          title
          fluid(maxWidth: 1600) {
            ...Image
          }
        }
        internal {
          type
        }
      }
    }
    footerCtaBlock: allContentfulCtaBlock(
      filter: { key: { eq: "community.footerCta" } }
    ) {
      nodes {
        id
        cta {
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulRegionalLink {
            ...RegionalLink
          }
        }
        body {
          body
        }
        node_locale
      }
    }
  }
`
